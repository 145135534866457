// Firehouse Subs CSS

import { componentsLibraryPrimitives as Primitives } from '@rbilabs/components-library';

import { LayoutWidth, ZIndex } from './enums';

export default {
  // Extra CSS Properties
  borderRadius: '4px',
  boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
  shadowColor: 'rgba(0, 0, 0, 0.1)',
  shadowOffset: '0 2px',
  shadowRadius: '6px',

  // Color Styles
  color: {
    accent: '#2F818A',
    alert: {
      default: {
        background: '#1F1F1F',
        text: '#ffffff',
      },
      info: {
        background: '#1F1F1F',
        text: '#ffffff',
      },
      success: {
        background: '#1F1F1F',
        text: '#ffffff',
      },
      warning: {
        background: '#c41230',
        text: '#ffffff',
      },
      error: {
        background: '#c41230',
        text: '#ffffff',
      },
      dark: {
        background: '#000000',
        text: '#ffffff',
      },
    },
    black: '#000000',
    background: '#F2F2F6',
    codeBlockBackground: '#323231',
    contrastBackground: Primitives.fhs.$fhsRed,
    disabledColor: '',
    disabledBorder: '',
    disabledBackground: '',
    error: '#c41230',
    errorHover: '',
    grey: {
      one: '#4a4a4a',
      two: '#716E6E',
      three: '#767676',
      four: '#dcdcdc',
      five: '#F9F3E3',
      six: '#7e7e7e',
      seven: '#6E6E6E',
      eight: '#b6b6b6',
      nine: '#d0d0d0',
      ten: '#efe7e3',
    },
    headerContrast: '#EF463B',
    highlight: '#FF7D00',
    icon: '#59BEC9',
    loyaltyHighlight: '#911987',
    navLinkColor: {
      active: '#c40000',
      hover: '#767676',
    },
    mobileNavButtonColor: '#1f1f1f',
    pricing: '#c90325',
    primary: '#B1080F',
    primaryHover: '#D8291E',
    secondary: '#BB5C16',
    secondaryHover: '',
    teal: '#97CEB9',
    tertiary: '#c41230',
    white: '#ffffff',
    cardBackground: '#fff',
    dialogBackground: '#fff',
    validated: '#5E8036',
    validateHover: '',
    success: '',
    red: '#d62300',
  },

  // Font Styles
  fontFamily: {
    base: 'Silverspoon, sans-serif',
    body: 'Monserrat, sans-serif',
    brand: 'Silverspoon Bold, sans-serif',
  },

  // Font Weight Styles
  fontWeight: {
    black: 800,
    heavy: 'bold',
    normal: 'normal',
    light: 300,
  },

  // Font Transforms Styles
  textTransform: {
    headlines: 'none',
    body: 'none',
  },

  // Z Index Styles
  zIndex: {
    min: ZIndex.MIN,
    max: ZIndex.MAX,
    overlay: ZIndex.OVERLAY,
    desktopHeader: ZIndex.DESKTOP_HEADER,
    top: ZIndex.TOP,
    normal: ZIndex.NORMAL,
    below: ZIndex.BELOW,
  },

  // Layout variables
  layout: {
    sectionMaxWidth: `${LayoutWidth.REGULAR}px`,
    smallerSectionMaxWidth: `${LayoutWidth.SMALL}px`,
    checkoutHeaderHeight: '57px',
    navHeight: {
      mobile: '56px',
      desktop: '78px',
    },
  },
};
