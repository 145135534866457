import styled from 'styled-components';

export const LOGO_TITLE_KEY = 'fhsLogoTitle';

export const StyledLogo = styled.div`
  width: 129px;
  /** Safari/WkWebView doesn't display the image without the height */
  height: 40.13px;
  display: flex;

  ${Styles.desktopLarge} {
    height: 48px;
    width: 188px;
    object-fit: contain;
    margin-block-start: 1px;
    margin-block-end: 0;
    margin-inline: auto;
    display: block;
    pointer-events: none;
  }
`;
